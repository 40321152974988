/* eslint-disable react/no-array-index-key */

import { useRouter } from "next/router";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback } from "react";

import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "stores";
import { selectEmail } from "stores/features/appBuilder/overview";
import { cartUpdated, selectCart } from "stores/features/cart";
import { selectPrivateKey } from "stores/features/project";
import { SECTION } from "utils/constants";
import urls from "utils/urls";

import IconClose from "./icons/IconClose.svg";
import styles from "./styles.module.scss";

interface Props {
  onClose?: () => void;
}

const ReadyToPublish: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const email = useAppSelector(selectEmail);
  const privateKey = useAppSelector(selectPrivateKey);
  const router = useRouter();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(
      cartUpdated({
        cart: {
          basicLicense: true,
        },
        emailAnalytics: email,
      })
    );

    router.push(urls.appBuilder(privateKey, SECTION.license));
  }, [dispatch, email, privateKey, router]);

  return (
    <div className={styles.readyToPublishContainer}>
      <Button className={styles.readyToPublishClose} onClick={onClose}>
        <IconClose />
      </Button>
      <div className={styles.readyToPublishTitle}>
        {t("containers.rightPanel.readyToPublish.title")}
      </div>

      <div className={styles.readyToPublishItem}>
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={t("containers.rightPanel.readyToPublish.notes")}
        />
      </div>

      <div className={styles.readyToPublishButtonContainer}>
        <Button
          className={styles.readyToPublishButton}
          disabled={cart.basicLicense}
          onClick={handleClick}
          size="lg"
          type="primary"
          wide
        >
          {cart.basicLicense
            ? t("common.addedToCart")
            : t("containers.rightPanel.readyToPublish.purchase")}
        </Button>
      </div>
    </div>
  );
};

export default ReadyToPublish;
