/* eslint-disable no-nested-ternary */

import classNames from "classnames";
import { useRouter } from "next/router";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import ActionButton from "components/common/ActionButton";
import { useAppDispatch, useAppSelector } from "stores";
import { selectEmail } from "stores/features/appBuilder/overview";
import { cartUpdated } from "stores/features/cart";
import { selectPrivateKey } from "stores/features/project";
import { SECTION } from "utils/constants";
import { useIsLicensed } from "utils/hooks";
import { activateSupport } from "utils/support";
import urls from "utils/urls";

import IconSimulator from "../icons/simulator.svg";
import styles from "./styles.module.scss";

interface Props {
  className?: string;
  standalone?: boolean;
}

const AppetizeMessage: React.FC<Props> = ({ className, standalone }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  const privateKey = useAppSelector(selectPrivateKey);
  const isLicensed = useIsLicensed();

  return (
    <div className={classNames(styles.container, className)}>
      <IconSimulator className={styles.icon} />

      <div className={styles.title}>
        {t("containers.rightPanel.appetize.limitReached")}
      </div>

      <div className={styles.error}>
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={
            standalone
              ? "containers.rightPanel.appetize.errorShare"
              : isLicensed
              ? "containers.rightPanel.appetize.errorPaid"
              : "containers.rightPanel.appetize.errorFree"
          }
        />
      </div>

      {!standalone && isLicensed && (
        <ActionButton
          action={t("common.contactUs")}
          buttonClassName={styles.button}
          containerClassName={styles.buttonContainer}
          onClick={() => activateSupport({ email })}
          type="secondary"
        />
      )}

      {!standalone && !isLicensed && (
        <ActionButton
          action={t("common.add")}
          subject={t("common.license")}
          buttonClassName={styles.button}
          containerClassName={styles.buttonContainer}
          onClick={() => {
            router.push(
              urls.appBuilder(privateKey, SECTION.license),
              undefined,
              { shallow: true }
            );
            if (!isLicensed) {
              dispatch(
                cartUpdated({
                  cart: { basicLicense: true },
                  emailAnalytics: email,
                  licensed: isLicensed,
                })
              );
            }
          }}
          type="secondary"
        />
      )}
    </div>
  );
};

export default AppetizeMessage;
