/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/iframe-has-title */

import classNames from "classnames";
import range from "lodash/range";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import { useLocalStorage, usePrevious } from "react-use";

import IconChevronLeft from "assets/images/icons/chevronLeft.svg";
import IconChevronRight from "assets/images/icons/chevronRight.svg";
import IconClone from "assets/images/icons/IconClone";
import IconClose from "assets/images/icons/IconClose";
import IconDownload from "assets/images/icons/IconDownload";
import IconInstall from "assets/images/icons/IconInstall";
import IconReload from "assets/images/icons/IconReload";
import IconStop from "assets/images/icons/IconStop";
import ActionButton from "components/common/ActionButton";
import Button from "components/common/Button";
import Select from "components/common/Select";
import Switch from "components/common/Switch";
import Tab from "components/common/Tab";
import Tooltip from "components/common/Tooltip";
import InstallAppModal from "components/containers/Share/InstallAppModal";
import { useDownloadBuild } from "components/sections/Build/utils";
import { useAppDispatch, useAppSelector } from "stores";
import {
  selectAndroidBuild,
  selectBuild,
  selectIosBuild,
} from "stores/features/appBuilder/build";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectEmail } from "stores/features/appBuilder/overview";
import {
  selectAppnumHashed,
  selectPreview,
} from "stores/features/appBuilder/preview";
import { selectIsBackupPreview } from "stores/features/backups";
import { metaUpdated, selectMeta } from "stores/features/meta";
import {
  selectPrivateKey,
  selectProjectCreated,
  selectPublicKey,
} from "stores/features/project";
import {
  selectIsInstallModalOpen,
  selectIsRightPanelCollapsed,
  selectRebuildOnSave,
  uiUpdated,
} from "stores/features/ui";
import { selectIsAuthenticated } from "stores/features/user";
import { trackEvent, trackLead } from "utils/analytics";
import api from "utils/api";
import { generateSignedQuery } from "utils/appetize";
import { SECTION } from "utils/constants";
import { getErrorStatus, handleError } from "utils/errors";
import { formatTime } from "utils/formatting";
import { useChrome, useIsLicensed, useMobile, usePhone } from "utils/hooks";
import { storageKeys, StorageRebuildOnSave } from "utils/storage";
import urls from "utils/urls";

import AppetizeMessage from "../AppetizeMessage";
import ReadyToPublish from "../ReadyToPublish";
import BuildButton from "./BuildButton";
import { useBuildStatus, useIncrementalBuild } from "./BuildButton/utils";
import {
  defaultDeviceDimensions,
  defaultDeviceTypes,
  DefaultModel,
  Device,
  deviceScale,
  DeviceType,
  deviceTypeOptions,
} from "./constants";
import IconExternal from "./images/external.svg";
import IconHome from "./images/IconHome";
import IconRotate from "./images/rotate.svg";
import IconScreenshot from "./images/screenshot.svg";
import IconUpload from "./images/upload.svg";
import styles from "./styles.module.scss";
import {
  allowVPN,
  useAppetizeMediaUpload,
  useCountdownTimer,
  useDebug,
  useIsPreviewFullScreen,
  useSimulatorPreview,
} from "./utils";

const deviceZoomOptions = range(150, 25, -25).map((zoomValue) => ({
  label: `${zoomValue}%`,
  value: `${zoomValue}`,
}));

interface Props {
  className?: string;
  standalone?: boolean;
}

const Preview: React.FC<Props> = ({ className, standalone }) => {
  const [downloadBuild] = useDownloadBuild();
  const dispatch = useAppDispatch();
  const simulatorIosPhoneRef = useRef<HTMLIFrameElement>(null);
  const simulatorIosTabletRef = useRef<HTMLIFrameElement>(null);
  const simulatorAndroidPhoneRef = useRef<HTMLIFrameElement>(null);
  const simulatorAndroidTabletRef = useRef<HTMLIFrameElement>(null);
  const build = useAppSelector(selectBuild);
  const iosBuild = useAppSelector(selectIosBuild);
  const androidBuild = useAppSelector(selectAndroidBuild);
  const email = useAppSelector(selectEmail);
  const isRightPanelCollapsed = useAppSelector(selectIsRightPanelCollapsed);
  const license = useAppSelector(selectLicense);
  const preview = useAppSelector(selectPreview);
  const privateKey = useAppSelector(selectPrivateKey);
  const publicKey = useAppSelector(selectPublicKey);
  const projectCreated = useAppSelector(selectProjectCreated);
  const appnumHashed = useAppSelector(selectAppnumHashed);
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const {
    isAppetizeBlocked,
    isShareDownloadsBlocked: isDownloadsBlocked,
    cloneKey,
  } = useAppSelector(selectMeta);
  const { isBuildingIosAppetize, isBuildingAndroidAppetize } = useBuildStatus();
  const isLicensed = useIsLicensed();
  const isMobile = useMobile();
  const isPhone = usePhone();
  const router = useRouter();
  const { asPath } = router;
  const isNewApp = !privateKey && !publicKey;
  const shareDeviceId = asPath.split("#").pop() || "";
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const isInstallAppModalOpen = useAppSelector(selectIsInstallModalOpen);

  const [isRebuildOnSave, setIsRebuildOnSave] =
    useLocalStorage<StorageRebuildOnSave>(storageKeys.rebuildOnSave(), {
      rebuildOnSave: true,
    });
  const rebuildOnSave = useAppSelector(selectRebuildOnSave);
  const { timers, startCountdown, stopCountdown } = useCountdownTimer();
  const sessionDuration = useRef<number>();

  const { currentDeviceType, setCurrentDeviceType } = useSimulatorPreview();
  const [debugMode, setDebugMode] = useState(false);
  const [chromeDevToolsUrl, setChromeDevToolsUrl] = useState();
  const [devices, setDevices] = useState<{ [key in DeviceType]: Device }>(
    () => ({
      [DeviceType.iphone]: {
        dimensions: defaultDeviceDimensions[DeviceType.iphone].iphone14pro,
        iframeRef: simulatorIosPhoneRef,
        isLaunched: false,
        model: "iphone14pro",
        orientation: "portrait",
        os: "ios",
        osVersion: "17.2",
        type: DeviceType.iphone,
      },
      [DeviceType.androidPhone]: {
        dimensions: defaultDeviceDimensions[DeviceType.androidPhone].pixel8,
        iframeRef: simulatorAndroidPhoneRef,
        isLaunched: false,
        model: "pixel8",
        orientation: "portrait",
        os: "android",
        osVersion: "14.0",
        type: DeviceType.androidPhone,
      },
      [DeviceType.ipad]: {
        dimensions:
          defaultDeviceDimensions[DeviceType.ipad].ipadair4thgeneration,
        iframeRef: simulatorIosTabletRef,
        isLaunched: false,
        model: "ipadair4thgeneration",
        orientation: "portrait",
        os: "ios",
        osVersion: "17.2",
        type: DeviceType.ipad,
      },
      [DeviceType.androidTablet]: {
        dimensions:
          defaultDeviceDimensions[DeviceType.androidTablet].galaxytabs7,
        iframeRef: simulatorAndroidTabletRef,
        isLaunched: false,
        model: "galaxytabs7",
        orientation: "portrait",
        os: "android",
        osVersion: "13.0",
        type: DeviceType.androidTablet,
      },
      [DeviceType.otherIos]: {
        dimensions: defaultDeviceDimensions[DeviceType.otherIos].otherIos,
        iframeRef: simulatorAndroidPhoneRef,
        isLaunched: false,
        model: "other",
        orientation: "portrait",
        os: "ios",
        osVersion: "",
        type: DeviceType.otherIos,
      },
      [DeviceType.otherAndroid]: {
        dimensions:
          defaultDeviceDimensions[DeviceType.otherAndroid].otherAndroid,
        iframeRef: null,
        isLaunched: false,
        model: "other",
        orientation: "portrait",
        os: "android",
        osVersion: "",
        type: DeviceType.otherAndroid,
      },
    })
  );

  const [loadedDeviceTypes, setLoadedDeviceTypes] = useState<DeviceType[]>([
    DeviceType.iphone,
  ]);
  const isChrome = useChrome();
  const [hasAutoStarted, setHasAutoStarted] = useState(false);
  const [sendingScreenshot, setSendingScreenshot] = useState(false);
  const [showReadyToPublish, setShowReadyToPublish] = useState(true);
  const [zoom, setZoom] = useState("100");
  const [zoomIos, setZoomIos] = useState("100");
  const [zoomAndroid, setZoomAndroid] = useState("100");
  const [zoomIpad, setZoomIpad] = useState(isPhone ? "50" : "100");
  const [zoomAndroidTablet, setZoomAndroidTablet] = useState(
    isPhone ? "50" : "100"
  );
  const { t } = useTranslation();

  const currentDevice = useMemo(
    () => devices[currentDeviceType],
    [currentDeviceType, devices]
  );

  const { isIosAppetizeOutdated, isAndroidAppetizeOutdated } =
    useIncrementalBuild({ type: "all", isRebuild: true });

  const shareDevice = useMemo(() => {
    switch (shareDeviceId) {
      case "iphone":
        return DeviceType.iphone;
      case "androidphone":
        return DeviceType.androidPhone;
      case "ipad":
        return DeviceType.ipad;
      default:
        return DeviceType.androidTablet;
    }
  }, [shareDeviceId]);

  const [prevUrl, setPrevUrl] = useState("");

  useEffect(() => {
    if (rebuildOnSave) {
      setIsRebuildOnSave({ rebuildOnSave });
    }
  }, [rebuildOnSave, setIsRebuildOnSave]);

  useEffect(() => {
    if (isRebuildOnSave?.rebuildOnSave !== rebuildOnSave) {
      dispatch(uiUpdated({ rebuildOnSave: isRebuildOnSave?.rebuildOnSave }));
    }
  }, [isRebuildOnSave, rebuildOnSave, dispatch]);

  useEffect(() => {
    if (standalone) {
      if (!loadedDeviceTypes.includes(shareDevice)) {
        setLoadedDeviceTypes([...loadedDeviceTypes, shareDevice]);
      }

      if (shareDeviceId === DeviceType.iphone.toLocaleLowerCase()) {
        setCurrentDeviceType(DeviceType.iphone);
      } else if (
        shareDeviceId === DeviceType.androidPhone.toLocaleLowerCase() ||
        shareDeviceId === "apk"
      ) {
        setCurrentDeviceType(DeviceType.androidPhone);
      } else if (shareDeviceId === DeviceType.ipad.toLocaleLowerCase()) {
        setCurrentDeviceType(DeviceType.ipad);
      } else if (
        shareDeviceId === DeviceType.androidTablet.toLocaleLowerCase()
      ) {
        setCurrentDeviceType(DeviceType.androidTablet);
      }
    }
  }, [
    loadedDeviceTypes,
    setCurrentDeviceType,
    shareDevice,
    shareDeviceId,
    standalone,
  ]);

  useEffect(() => {
    if (!loadedDeviceTypes.includes(currentDeviceType)) {
      setLoadedDeviceTypes([...loadedDeviceTypes, currentDeviceType]);
    }
  }, [currentDeviceType, loadedDeviceTypes]);

  const isFullScreen = useIsPreviewFullScreen(currentDevice, +zoom);
  const debugUrl = useDebug(currentDevice);

  const currentDeviceTypeOptions = useMemo(() => {
    let currentOptions: { label: string; value: string }[];
    if (currentDeviceType === DeviceType.iphone) {
      currentOptions = Object.entries(deviceTypeOptions[DeviceType.iphone]).map(
        ([model]) => ({
          label: t(`containers.rightPanel.preview.deviceTypeOptions.${model}`),
          value: model,
        })
      );
    } else if (currentDeviceType === DeviceType.androidPhone) {
      currentOptions = Object.entries(
        deviceTypeOptions[DeviceType.androidPhone]
      ).map(([model]) => ({
        label: t(`containers.rightPanel.preview.deviceTypeOptions.${model}`),
        value: model,
      }));
    } else if (currentDeviceType === DeviceType.ipad) {
      currentOptions = Object.entries(deviceTypeOptions[DeviceType.ipad]).map(
        ([model]) => ({
          label: t(`containers.rightPanel.preview.deviceTypeOptions.${model}`),
          value: model,
        })
      );
    } else
      currentOptions = Object.entries(
        deviceTypeOptions[DeviceType.androidTablet]
      ).map(([model]) => ({
        label: t(`containers.rightPanel.preview.deviceTypeOptions.${model}`),
        value: model,
      }));

    return currentOptions;
  }, [currentDeviceType, t]);

  const handleOnAction = useCallback(() => {
    [
      devices.iphone,
      devices.ipad,
      devices.androidPhone,
      devices.androidTablet,
    ].forEach((device) => {
      if (
        device.isLaunched &&
        device.iframeRef?.current?.contentWindow &&
        device.iframeRef.current.contentWindow.postMessage
      ) {
        device.iframeRef.current.contentWindow.postMessage("heartbeat", "*");
      }
    });
  }, [
    devices.androidPhone,
    devices.androidTablet,
    devices.ipad,
    devices.iphone,
  ]);

  useIdleTimer({
    onAction: handleOnAction,
    throttle: 15000,
  });

  const currentSimulatorRef = useMemo(() => {
    if (currentDeviceType === "iphone") {
      return simulatorIosPhoneRef;
    } else if (currentDeviceType === "ipad") {
      return simulatorIosTabletRef;
    } else if (currentDeviceType === "androidPhone") {
      return simulatorAndroidPhoneRef;
    } else if (currentDeviceType === "androidTablet") {
      return simulatorAndroidTabletRef;
    }

    return null;
  }, [currentDeviceType]);

  const showDownloadButton = useMemo(() => {
    if (
      !privateKey ||
      (isBuildingAndroidAppetize && currentDevice.os === "android") ||
      (isBuildingIosAppetize && currentDevice.os === "ios")
    ) {
      return false;
    }
    return true;
  }, [
    currentDevice.os,
    isBuildingAndroidAppetize,
    isBuildingIosAppetize,
    privateKey,
  ]);

  const missingPublicKey = useMemo(() => {
    if (currentDevice.os === "ios") {
      return !preview.appetizePublicKey;
    } else {
      return !preview.appetizeAndroidPublicKey;
    }
  }, [
    currentDevice.os,
    preview.appetizePublicKey,
    preview.appetizeAndroidPublicKey,
  ]);

  const disabled = missingPublicKey || isAppetizeBlocked;

  // Force reset the preview after building.
  const [buildKey, setBuildKey] = useState<{ [key in DeviceType]: number }>({
    [DeviceType.iphone]: 0,
    [DeviceType.androidPhone]: 0,
    [DeviceType.ipad]: 0,
    [DeviceType.androidTablet]: 0,
    [DeviceType.otherIos]: 0,
    [DeviceType.otherAndroid]: 0,
  });

  const prevIosStatus = usePrevious(iosBuild.type.appetize);
  const prevAndroidStatus = usePrevious(androidBuild.type.appetize);
  const prevDisableDStatus = usePrevious(disabled);

  // Auto Start Appetize
  useEffect(() => {
    if (hasAutoStarted) return undefined;
    setHasAutoStarted(true);
    if (prevDisableDStatus && !disabled && !isAuthenticated) {
      const timeoutId = setTimeout(() => {
        if (
          simulatorIosPhoneRef.current &&
          simulatorIosPhoneRef.current.contentWindow
        ) {
          const iframeDoc = simulatorIosPhoneRef.current.contentWindow.document;
          const image = iframeDoc.querySelector("img");
          if (image) {
            image.click();
          }
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [disabled, hasAutoStarted, isAuthenticated, prevDisableDStatus]);

  // iOS Simulators
  useEffect(() => {
    if (
      prevIosStatus === "buildRequested" &&
      iosBuild.type.appetize === "current" &&
      currentDevice.isLaunched
    ) {
      setBuildKey((prevState) => ({
        ...prevState,
        [DeviceType.iphone]: prevState[DeviceType.iphone] + 1,
        [DeviceType.ipad]: prevState[DeviceType.ipad] + 1,
      }));

      setDevices((prevDevices) => ({
        ...prevDevices,
        [DeviceType.iphone]: {
          ...prevDevices[DeviceType.iphone],
          isLaunched: false,
        },
        [DeviceType.ipad]: {
          ...prevDevices[DeviceType.ipad],
          isLaunched: false,
        },
      }));
    } else if (
      prevIosStatus !== "buildRequested" &&
      iosBuild.type.appetize === "buildRequested"
    ) {
      setShowReadyToPublish(true);
    }
  }, [currentDevice.isLaunched, iosBuild.type.appetize, prevIosStatus]);

  // Android Simulators
  useEffect(() => {
    if (
      prevAndroidStatus === "buildRequested" &&
      androidBuild.type.appetize === "current"
    ) {
      setBuildKey((prevState) => ({
        ...prevState,
        [DeviceType.androidPhone]: prevState[DeviceType.androidPhone] + 1,
        [DeviceType.androidTablet]: prevState[DeviceType.androidTablet] + 1,
      }));
      setDevices((prevDevices) => ({
        ...prevDevices,
        [DeviceType.androidPhone]: {
          ...prevDevices[DeviceType.androidPhone],
          isLaunched: false,
        },
        [DeviceType.androidTablet]: {
          ...prevDevices[DeviceType.androidTablet],
          isLaunched: false,
        },
      }));
    } else if (
      prevAndroidStatus !== "buildRequested" &&
      androidBuild.type.appetize === "buildRequested"
    ) {
      setShowReadyToPublish(true);
    }
  }, [androidBuild.type.appetize, currentSimulatorRef, prevAndroidStatus]);

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      let deviceTarget: Device;
      switch (e.source) {
        case devices.iphone.iframeRef?.current?.contentWindow:
          deviceTarget = devices.iphone;
          break;
        case devices.ipad.iframeRef?.current?.contentWindow:
          deviceTarget = devices.ipad;
          break;
        case devices.androidPhone.iframeRef?.current?.contentWindow:
          deviceTarget = devices.androidPhone;
          break;
        case devices.androidTablet.iframeRef?.current?.contentWindow:
          deviceTarget = devices.androidTablet;
          break;
        default:
          return;
      }

      if (e.data?.type === "sessionConnecting") {
        (async () => {
          sessionDuration.current = 120;

          try {
            const sessionDurationValue =
              await api.appetize.getAppetizeSessionDuration(
                privateKey || publicKey
              );

            if (sessionDurationValue === -1) {
              sessionDuration.current = undefined;
            } else if (typeof sessionDurationValue === "number") {
              sessionDuration.current = sessionDurationValue;
            }
          } catch (error) {
            if (getErrorStatus(error) === 429) {
              dispatch(metaUpdated({ isAppetizeBlocked: true }));
              sessionDuration.current = 0;
            }
            handleError(error, { t });
          }

          if (
            sessionDuration.current === undefined ||
            sessionDuration.current > 0
          ) {
            setDevices((prevState) => {
              const nextDevice = { ...prevState[deviceTarget.type] };
              nextDevice.isLaunched = false;
              nextDevice.connectionString = e.data.path;
              nextDevice.sessionToken = e.data.token;

              const nextState = { ...prevState };
              nextState[deviceTarget.type] = nextDevice;

              return nextState;
            });
          }
        })();
      } else if (e.data === "sessionEnded") {
        setChromeDevToolsUrl(undefined);
        setDevices((prevState) => {
          const nextDevice = { ...prevState[deviceTarget.type] };
          nextDevice.isLaunched = false;
          nextDevice.connectionString = undefined;
          nextDevice.sessionToken = undefined;

          const nextState = { ...prevState };
          nextState[deviceTarget.type] = nextDevice;

          return nextState;
        });
      } else if (e.data === "concurrentQueued") {
        toast(t("errors.appetizeConcurrentQueue"), { type: "warning" });
      } else if (e.data === "sessionQueued") {
        toast(t("errors.appetizeSystemQueue"), { type: "warning" });
      } else if (e.data === "firstFrameReceived") {
        const simulatorRef = devices[deviceTarget.type].iframeRef;

        if (
          sessionDuration.current === undefined ||
          sessionDuration.current > 0
        ) {
          if (deviceTarget.os === "ios") {
            trackEvent("ios_simulator");
            trackLead({
              email,
              ios_simulator_played: true,
              licensed: isLicensed,
            });
          } else {
            trackEvent("android_simulator");
            trackLead({
              email,
              android_simulator_played: true,
              licensed: isLicensed,
            });
          }

          setDevices((prevState) => {
            const nextDevice = { ...prevState[deviceTarget.type] };
            nextDevice.isLaunched = true;

            const nextState = { ...prevState };
            nextState[deviceTarget.type] = nextDevice;

            return nextState;
          });

          if (sessionDuration.current) {
            startCountdown(deviceTarget.type, sessionDuration.current, () => {
              if (
                simulatorRef?.current?.contentWindow &&
                simulatorRef.current.contentWindow.postMessage
              ) {
                simulatorRef.current.contentWindow.postMessage(
                  "endSession",
                  "*"
                );
                toast(
                  t("errors.appetizeSessionLimit", {
                    deviceType: t(
                      `containers.rightPanel.preview.deviceTypes.${deviceTarget.type}`
                    ),
                  }),
                  {
                    toastId: deviceTarget.type,
                    type: "error",
                  }
                );
              }
            });
          }
        } else if (
          simulatorRef?.current?.contentWindow &&
          simulatorRef.current.contentWindow.postMessage
        ) {
          simulatorRef.current.contentWindow.postMessage("endSession", "*");
        }
      } else if (e.data?.type === "deviceDimensions") {
        const width = ((e.data?.value?.width || 0) * deviceScale) / 100;
        const height = ((e.data?.value?.height || 0) * deviceScale) / 100;

        setDevices((prevState) => {
          const nextDevice = { ...prevState[deviceTarget.type] };
          nextDevice.dimensions[nextDevice.orientation].width = width;
          nextDevice.dimensions[nextDevice.orientation].height = height;

          const nextState = { ...prevState };
          nextState[deviceTarget.type] = nextDevice;

          return nextState;
        });
      } else if (e.data?.type === "orientationChanged") {
        setDevices((prevState) => {
          const nextDevice = { ...prevState[deviceTarget.type] };
          nextDevice.orientation =
            nextDevice.orientation === "portrait" ? "landscape" : "portrait";

          const nextState = { ...prevState };
          nextState[deviceTarget.type] = nextDevice;

          return nextState;
        });
      } else if (e.data?.type === "chromeDevToolsUrl" && e.data?.value) {
        setChromeDevToolsUrl(e.data.value);
      } else if (e.data?.type === "screenshot") {
        setSendingScreenshot(false);
      } else if (e.data?.type === "userError" && e.data?.value) {
        toast(e.data.value, { type: "error" });
      } else if (e.data?.type === "userErrorMessage" && e.data?.message) {
        toast(e.data.message, { type: "error" });
      }
    };

    window.addEventListener("message", messageListener, false);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [
    devices,
    dispatch,
    email,
    isLicensed,
    license.app.status,
    loadedDeviceTypes,
    privateKey,
    publicKey,
    startCountdown,
    t,
  ]);

  useEffect(() => {
    if (isPhone && currentDeviceType === DeviceType.ipad) {
      setZoomIpad("50");
      setZoom("50");
    } else if (isPhone && currentDeviceType === DeviceType.androidTablet) {
      setZoomAndroidTablet("50");
      setZoom("50");
    }
  }, [currentDeviceType, isMobile, isPhone]);

  const deviceZoomSelected = useMemo(
    () => deviceZoomOptions.find(({ value }) => value === zoom),
    [zoom]
  );

  const deviceModelSelected = useMemo(
    () =>
      currentDeviceTypeOptions.find(
        ({ value }) => value === currentDevice.model
      ) as { label: string; value: string },

    [currentDevice.model, currentDeviceTypeOptions]
  );

  const currentDeviceOsOptions = useMemo(() => {
    const osOptions = deviceTypeOptions[currentDeviceType as keyof object][
      deviceModelSelected.value as keyof object
    ] as [];
    const device = [DeviceType.iphone, DeviceType.ipad].includes(
      currentDeviceType
    )
      ? t("common.ios")
      : t("common.android");
    return osOptions.map((os: string) => ({
      label: `${device} ${(os as string).replace(/\.?0+$/, "")}`,
      value: os as string,
    }));
  }, [currentDeviceType, deviceModelSelected.value, t]);

  const deviceOsSelected = useMemo(
    () =>
      currentDeviceOsOptions.find(
        ({ value }) => value === currentDevice.osVersion
      ) as { label: string; value: string },
    [currentDevice.osVersion, currentDeviceOsOptions]
  );

  const handleDevToolsClick = useCallback(() => {
    if (!chromeDevToolsUrl) {
      toast(t("containers.rightPanel.preview.devToolsError"), {
        type: "error",
      });
    } else if (chromeDevToolsUrl && !isChrome) {
      toast(t("containers.rightPanel.preview.isChrome"), { type: "warning" });
    } else {
      window.open(chromeDevToolsUrl, "_blank");
    }
  }, [chromeDevToolsUrl, isChrome, t]);

  const handleDeviceChange = useCallback(
    (nextDeviceType: DeviceType) => {
      if (!loadedDeviceTypes.includes(nextDeviceType)) {
        setLoadedDeviceTypes([...loadedDeviceTypes, nextDeviceType]);
      }

      if (standalone && nextDeviceType !== currentDeviceType) {
        router.push(
          urls.share(publicKey, nextDeviceType.toLocaleLowerCase()),
          undefined,
          {
            shallow: true,
          }
        );
      }
      if (nextDeviceType === DeviceType.iphone) {
        setZoom(zoomIos);
      } else if (nextDeviceType === DeviceType.androidPhone) {
        setZoom(zoomAndroid);
      } else if (nextDeviceType === DeviceType.ipad) {
        setZoom(zoomIpad);
      } else if (nextDeviceType === DeviceType.androidTablet) {
        setZoom(zoomAndroidTablet);
      }
      setCurrentDeviceType(nextDeviceType);
    },
    [
      currentDeviceType,
      loadedDeviceTypes,
      publicKey,
      router,
      setCurrentDeviceType,
      standalone,
      zoomAndroid,
      zoomAndroidTablet,
      zoomIos,
      zoomIpad,
    ]
  );

  const handleZoomChange = useCallback(
    (nextZoom: string) => {
      setZoom(nextZoom);
      if (currentDeviceType === DeviceType.iphone) {
        setZoomIos(nextZoom);
      } else if (currentDeviceType === DeviceType.androidPhone) {
        setZoomAndroid(nextZoom);
      } else if (currentDeviceType === DeviceType.ipad) {
        setZoomIpad(nextZoom);
      } else if (currentDeviceType === DeviceType.androidTablet) {
        setZoomAndroidTablet(nextZoom);
      }
    },
    [currentDeviceType]
  );

  const handleDeviceModelChange = useCallback(
    (nextDeviceModel: string) => {
      if (nextDeviceModel === "debug") {
        window.open(debugUrl, "_blank");
        return;
      }

      let deviceTarget: Device;
      let deviceType: DeviceType;

      if (currentDeviceType === DeviceType.iphone) {
        deviceTarget = devices.iphone;
        deviceType = DeviceType.iphone;
      } else if (currentDeviceType === DeviceType.androidPhone) {
        deviceTarget = devices.androidPhone;
        deviceType = DeviceType.androidPhone;
      } else if (currentDeviceType === DeviceType.ipad) {
        deviceTarget = devices.ipad;
        deviceType = DeviceType.ipad;
      } else {
        deviceTarget = devices.androidTablet;
        deviceType = DeviceType.androidTablet;
      }

      const osArray: string[] =
        deviceTypeOptions[deviceType as keyof object][nextDeviceModel];
      setDevices((prevState) => {
        const nextDevice = { ...prevState[deviceTarget.type] };
        nextDevice.model = nextDeviceModel;
        nextDevice.osVersion = osArray[osArray.length - 1];

        nextDevice.dimensions =
          defaultDeviceDimensions[deviceType][nextDeviceModel];
        const nextState = { ...prevState };
        nextState[deviceTarget.type] = nextDevice;

        return nextState;
      });
    },
    [
      currentDeviceType,
      debugUrl,
      devices.androidPhone,
      devices.androidTablet,
      devices.ipad,
      devices.iphone,
    ]
  );

  const handleDeviceOsChange = useCallback(
    (nextDeviceOs: string) => {
      let deviceTarget: Device;

      if (currentDeviceType === DeviceType.iphone) {
        deviceTarget = devices.iphone;
      } else if (currentDeviceType === DeviceType.androidPhone) {
        deviceTarget = devices.androidPhone;
      } else if (currentDeviceType === DeviceType.ipad) {
        deviceTarget = devices.ipad;
      } else {
        deviceTarget = devices.androidTablet;
      }

      setDevices((prevState) => {
        const nextDevice = { ...prevState[deviceTarget.type] };
        nextDevice.osVersion = nextDeviceOs;

        const nextState = { ...prevState };
        nextState[deviceTarget.type] = nextDevice;

        return nextState;
      });
    },
    [
      currentDeviceType,
      devices.androidPhone,
      devices.androidTablet,
      devices.ipad,
      devices.iphone,
    ]
  );

  const handleOrientationChange = useCallback(() => {
    if (
      currentDevice.iframeRef &&
      currentDevice.iframeRef?.current &&
      currentDevice.iframeRef.current.contentWindow &&
      currentDevice.iframeRef.current.contentWindow.postMessage
    ) {
      currentDevice.iframeRef?.current?.contentWindow?.postMessage(
        "rotateRight",
        "*"
      );
    }

    if (!currentDevice.isLaunched) {
      setDevices((prevState) => {
        const nextDevice = { ...prevState[currentDevice.type] };
        nextDevice.orientation =
          nextDevice.orientation === "portrait" ? "landscape" : "portrait";

        const nextState = { ...prevState };
        nextState[currentDevice.type] = nextDevice;

        return nextState;
      });
    }
  }, [currentDevice]);

  const handleHomeClick = useCallback(() => {
    if (
      currentSimulatorRef?.current?.contentWindow &&
      currentSimulatorRef.current.contentWindow.postMessage
    ) {
      currentSimulatorRef.current.contentWindow.postMessage(
        "emitHomeButton",
        "*"
      );
    }
  }, [currentSimulatorRef]);

  const handleScreenshotClick = useCallback(() => {
    if (
      currentSimulatorRef?.current?.contentWindow &&
      currentSimulatorRef.current.contentWindow.postMessage
    ) {
      setSendingScreenshot(true);

      currentSimulatorRef.current.contentWindow.postMessage(
        "saveScreenshot",
        "*"
      );
    }
  }, [currentSimulatorRef]);

  const handleRestartSessionClick = useCallback(() => {
    if (
      currentSimulatorRef?.current?.contentWindow &&
      currentSimulatorRef.current.contentWindow.postMessage
    ) {
      currentSimulatorRef.current.contentWindow.postMessage("restartApp", "*");
    }
  }, [currentSimulatorRef]);

  const handleEndSessionClick = useCallback(() => {
    stopCountdown(currentDeviceType);
    if (
      currentSimulatorRef?.current?.contentWindow &&
      currentSimulatorRef.current.contentWindow.postMessage
    ) {
      currentSimulatorRef.current.contentWindow.postMessage("endSession", "*");
    }
  }, [currentDeviceType, currentSimulatorRef, stopCountdown]);

  const getIframeOptions = useCallback(
    (device: Device) => {
      const appetizePublicKey =
        device.os === "ios"
          ? preview.appetizePublicKey
          : preview.appetizeAndroidPublicKey;

      const interceptProxy = debugMode ? "&proxy=intercept" : "";
      const licensedSimulator =
        isLicensed || allowVPN(projectCreated)
          ? "&params=%7B%22gonativeLicensed%22%3A%20true%7D"
          : "";
      const mParameter = license.hasDevToolkit
        ? "&m=d"
        : isLicensed
        ? "&m=p"
        : "";
      const median = generateSignedQuery(
        `buildId=${appetizePublicKey}${mParameter}`
      );

      const srcUrl = `https://appetize.io/embed/${appetizePublicKey}?centered=true&device=${device.model}&osVersion=${device.osVersion}&orientation=portrait&scale=${deviceScale}&xdocMsg=true${interceptProxy}${licensedSimulator}&median=${median}`;
      const srDocIphone = `<html><body style='margin:0px;cursor: pointer;'><img onclick='window.location.href=&quot;${srcUrl}&autoplay=true&quot;' style='width: 100%; max-width: 329px;' src='/static/images/app-builder/ios.png'/></body></html>`;
      const srDocAndroid = `<html><body style='margin:0px;cursor: pointer;'><img onclick='window.location.href=&quot;${srcUrl}&autoplay=true&quot;' style='width: 100%; max-width: 329px;' src='/static/images/app-builder/android.png'/></body></html>`;
      const srcDocAndroidTablet = `<html><body style='margin:0px;cursor: pointer;'><img onclick='window.location.href=&quot;${srcUrl}&autoplay=true&quot;' style='width: 100%; max-width: 718px;' src='/static/images/app-builder/androidTablet.png'/></body></html>`;
      const srcDocIpad = `<html><body style='margin:0px;cursor: pointer;'><img onclick='window.location.href=&quot;${srcUrl}&autoplay=true&quot;' style='width: 100%; max-width: 924px;' src='/static/images/app-builder/ipad.png'/></body></html>`;

      const srcDoc =
        ([DefaultModel.iphone as string].includes(device.model) &&
          srDocIphone) ||
        ([DefaultModel.androidPhone as string].includes(device.model) &&
          srDocAndroid) ||
        ([DefaultModel.androidTablet as string].includes(device.model) &&
          srcDocAndroidTablet) ||
        ([DefaultModel.ipad as string].includes(device.model) && srcDocIpad);

      const src = !defaultDeviceTypes.includes(device.model) && srcUrl;

      return {
        className: classNames(
          styles.previewContentItem,
          device.type !== currentDeviceType && styles.disabled
        ),
        srcDoc: srcDoc || undefined,
        src: src || undefined,
        height: device.dimensions[device.orientation].height,
        ref: device.iframeRef,
        width: device.dimensions[device.orientation].width,
        scrolling: "no",
        style: {
          transform: `scale(${+zoom * 0.01})`,
          transformOrigin: "top left",
        },
        title: t("containers.rightPanel.preview.title"),
      } as React.HTMLAttributes<HTMLIFrameElement>;
    },
    [
      preview.appetizePublicKey,
      preview.appetizeAndroidPublicKey,
      debugMode,
      isLicensed,
      projectCreated,
      license.hasDevToolkit,
      currentDeviceType,
      zoom,
      t,
    ]
  );

  const placeholderImage = useMemo(() => {
    if (currentDeviceType === DeviceType.androidTablet) {
      return "/static/images/app-builder/androidTablet.png";
    } else if (currentDeviceType === DeviceType.ipad) {
      return "/static/images/app-builder/ipad.png";
    } else if (currentDeviceType === DeviceType.androidPhone) {
      return "/static/images/app-builder/android.png";
    } else return "/static/images/app-builder/ios.png";
  }, [currentDeviceType]);

  const {
    disabled: appetizeUploadDisabled,
    isUploading: appetizeUploading,
    handleUpload,
  } = useAppetizeMediaUpload(currentDevice);

  return (
    <div
      className={classNames(
        styles.preview,
        {
          [styles.fullScreen]: isFullScreen && !standalone,
          [styles.collapsed]: isRightPanelCollapsed && !standalone,
          [styles.standalone]: standalone,
        },
        className
      )}
    >
      {!standalone && (
        <header
          className={classNames(
            styles.previewHeader,
            isRightPanelCollapsed && styles.collapsed
          )}
        >
          {!isRightPanelCollapsed && (
            <Tooltip
              label={t("containers.rightPanel.preview.simulator")}
              placement="right"
            >
              <Button
                className={styles.buttonExternal}
                disabled={!publicKey}
                href={urls.share(
                  publicKey,
                  currentDeviceType.toLocaleLowerCase()
                )}
                target="_blank"
                type={publicKey ? "text" : undefined}
              >
                <h2
                  className={classNames(styles.previewHeaderTitle, {
                    [styles.licensed]: isLicensed,
                    [styles.readyToPublishHidden]:
                      disabled || !showReadyToPublish || isBackupPreview,
                  })}
                >
                  {t("containers.rightPanel.preview.title")}
                </h2>

                {publicKey && <IconExternal />}
              </Button>
            </Tooltip>
          )}
          <button
            className={classNames(
              styles.previewToggle,
              isRightPanelCollapsed && styles.collapsed
            )}
            onClick={() =>
              dispatch(
                uiUpdated({ isRightPanelCollapsed: !isRightPanelCollapsed })
              )
            }
            type="button"
          >
            <span className={styles.previewToggleContent}>
              {isRightPanelCollapsed && (
                <h2
                  className={classNames(
                    styles.previewHeaderTitle,
                    styles.collapsed,
                    {
                      [styles.licensed]: isLicensed,
                      [styles.readyToPublishHidden]: !showReadyToPublish,
                    }
                  )}
                >
                  {t("containers.rightPanel.preview.title")}
                </h2>
              )}
              <Tooltip
                label={
                  // eslint-disable-next-line no-nested-ternary
                  isRightPanelCollapsed
                    ? t("common.expand")
                    : !isFullScreen
                    ? t("common.collapse")
                    : t("common.closeSimulator")
                }
                placement="left"
              >
                {isRightPanelCollapsed ? (
                  <IconChevronLeft className={styles.icon} />
                ) : (
                  <div>
                    {!isFullScreen ? (
                      <IconChevronRight className={styles.icon} />
                    ) : (
                      <IconClose className={styles.icon} />
                    )}
                  </div>
                )}
              </Tooltip>
            </span>
          </button>
        </header>
      )}
      {(!isRightPanelCollapsed || standalone) && (
        <div>
          <div className={styles.previewOptions}>
            <div className={styles.previewOptionsRow}>
              <div className={styles.tabContainer}>
                <Tab
                  active={currentDeviceType === DeviceType.iphone}
                  className={classNames(
                    styles.tab,
                    isIosAppetizeOutdated && styles.outdated
                  )}
                  onClick={() => handleDeviceChange(DeviceType.iphone)}
                >
                  {t("containers.rightPanel.preview.deviceTypes.iphone")}
                </Tab>
                <Tab
                  active={currentDeviceType === DeviceType.androidPhone}
                  className={classNames(
                    styles.tab,
                    isAndroidAppetizeOutdated && styles.outdated
                  )}
                  onClick={() => handleDeviceChange(DeviceType.androidPhone)}
                >
                  {t("containers.rightPanel.preview.deviceTypes.androidPhone")}
                </Tab>
                <Tab
                  active={currentDeviceType === DeviceType.ipad}
                  className={classNames(
                    styles.tab,
                    isIosAppetizeOutdated && styles.outdated
                  )}
                  onClick={() => handleDeviceChange(DeviceType.ipad)}
                >
                  {t("containers.rightPanel.preview.deviceTypes.ipad")}
                </Tab>
                <Tab
                  active={currentDeviceType === DeviceType.androidTablet}
                  className={classNames(
                    styles.tab,
                    isAndroidAppetizeOutdated && styles.outdated
                  )}
                  onClick={() => handleDeviceChange(DeviceType.androidTablet)}
                >
                  {t("containers.rightPanel.preview.deviceTypes.androidTablet")}
                </Tab>
              </div>
            </div>
            <div className={styles.rowContainer}>
              <div
                className={classNames(
                  styles.previewOptionsRow,
                  styles.topRow,
                  styles.previewOptionsButtons,
                  styles.rowWidth
                )}
              >
                <div className={styles.zoomAndRotateContainer}>
                  <Tooltip
                    label={t("containers.rightPanel.preview.deviceZoomsTitle")}
                    placement="left"
                  >
                    <div>
                      <Select
                        className={classNames(
                          styles.previewOptionsSelectZoomMenu,
                          styles.zoom,
                          !isFullScreen && styles.selectContainer
                        )}
                        isDisabled={
                          disabled ||
                          (isPhone && currentDeviceType === DeviceType.ipad)
                        }
                        isSearchable={false}
                        onChange={(value) =>
                          handleZoomChange(value?.value as string)
                        }
                        options={deviceZoomOptions}
                        placeholder={t(
                          "containers.rightPanel.preview.deviceZoomsTitle"
                        )}
                        value={deviceZoomSelected}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    label={t("containers.rightPanel.preview.rotate")}
                    placement="bottom"
                  >
                    <Button
                      className={classNames(
                        styles.previewOptionsButtonsItem,
                        (!currentDevice.isLaunched || disabled) &&
                          styles.disabled
                      )}
                      disabled={!currentDevice.isLaunched || disabled}
                      onClick={handleOrientationChange}
                    >
                      <IconRotate />
                    </Button>
                  </Tooltip>
                </div>
                <div className={styles.previewOptionsButtonsContainer}>
                  <Tooltip
                    label={t("containers.rightPanel.preview.home")}
                    placement="bottom"
                  >
                    <Button
                      className={classNames(
                        styles.previewOptionsButtonsItem,
                        (!currentDevice.isLaunched || disabled) &&
                          styles.disabled
                      )}
                      disabled={!currentDevice.isLaunched || disabled}
                      onClick={handleHomeClick}
                    >
                      <IconHome />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label={t("containers.rightPanel.preview.upload")}
                    placement="bottom"
                  >
                    <Button
                      className={classNames(
                        styles.previewOptionsButtonsItem,
                        (!currentDevice.isLaunched ||
                          disabled ||
                          appetizeUploadDisabled ||
                          appetizeUploading) &&
                          styles.disabled
                      )}
                      disabled={
                        !currentDevice.isLaunched ||
                        disabled ||
                        appetizeUploadDisabled ||
                        appetizeUploading
                      }
                      onClick={handleUpload}
                    >
                      <IconUpload />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    label={t("containers.rightPanel.preview.screenshot")}
                    placement="bottom"
                  >
                    <Button
                      className={classNames(
                        styles.previewOptionsButtonsItem,
                        (!currentDevice.isLaunched || disabled) &&
                          styles.disabled
                      )}
                      disabled={
                        !currentDevice.isLaunched ||
                        disabled ||
                        sendingScreenshot
                      }
                      onClick={handleScreenshotClick}
                    >
                      <IconScreenshot />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isRightPanelCollapsed && !standalone && !isBackupPreview && (
        <div className={classNames(styles.noteAndButtonContainer)}>
          <BuildButton
            key={currentDevice.model}
            buildButtonClassName={styles.download}
            buildButtonContainerClassName={classNames(styles.downloadButton)}
            renderLeftItems={
              <div className={styles.debugSwitch}>
                <Switch
                  inputComponent
                  checked={rebuildOnSave}
                  label={t("common.rebuildOnSave")}
                  inline
                  onChange={() => {
                    const newValue = !rebuildOnSave;
                    setIsRebuildOnSave({ rebuildOnSave: newValue }); // Update localStorage
                    dispatch(uiUpdated({ rebuildOnSave: newValue })); // Update Redux
                  }}
                />
              </div>
            }
            type={
              currentDevice.os === "ios" ? "iosAppetize" : "androidAppetize"
            }
            isRebuild
            isSimulatorButton
          />
        </div>
      )}
      <div
        className={classNames(
          styles.previewContent,
          isRightPanelCollapsed && !standalone && styles.collapsed,
          standalone && styles.standalone
        )}
        style={{
          minHeight:
            Math.ceil(
              currentDevice.dimensions[currentDevice.orientation].height *
                +zoom *
                0.01
            ) + 5,
          width: Math.ceil(
            currentDevice.dimensions[currentDevice.orientation].width *
              +zoom *
              0.01
          ),
        }}
      >
        {disabled || isBackupPreview ? (
          <div className={styles.placeholderImageContainer}>
            <img
              alt=""
              className={classNames(
                styles.placeholderImage,
                isAppetizeBlocked && styles.transparent
              )}
              src={placeholderImage}
              height={
                defaultDeviceDimensions[currentDeviceType][
                  deviceModelSelected.value
                ].portrait.height
              }
              width={
                defaultDeviceDimensions[currentDeviceType][
                  deviceModelSelected.value
                ].portrait.width
              }
            />

            {isAppetizeBlocked && !missingPublicKey && (
              <AppetizeMessage
                className={styles.overlay}
                standalone={standalone}
              />
            )}
          </div>
        ) : (
          <>
            {loadedDeviceTypes.map((deviceType) => (
              <iframe
                key={`${deviceType}:${buildKey[deviceType]}`}
                {...getIframeOptions(devices[deviceType])}
              />
            ))}
          </>
        )}
      </div>

      {!currentDevice.isLaunched && (!isRightPanelCollapsed || standalone) && (
        <div>
          <div
            className={classNames(
              styles.previewOptionsRow,
              styles.selectModelRow,
              styles.rowWidth,
              standalone && styles.standalone,
              styles.previewOptionsButtons
            )}
          >
            <Select
              className={classNames(
                styles.previewOptionsSelectZoomMenu,
                currentDeviceType === DeviceType.iphone
                  ? styles.iosModel
                  : styles.androidModel,
                !isFullScreen && styles.selectContainer
              )}
              isDisabled={disabled}
              isSearchable={false}
              onChange={(value) =>
                handleDeviceModelChange(value?.value as string)
              }
              options={currentDeviceTypeOptions}
              value={deviceModelSelected}
              menuPlacement="top"
            />
            <Select
              className={classNames(
                styles.previewOptionsSelectZoomMenu,
                styles.os,
                !isFullScreen && styles.selectContainer
              )}
              isDisabled={disabled}
              isSearchable={false}
              onChange={(value) => handleDeviceOsChange(value?.value as string)}
              options={currentDeviceOsOptions}
              value={deviceOsSelected}
              menuPlacement="top"
            />
          </div>
        </div>
      )}

      {currentDevice.isLaunched && (standalone || !isRightPanelCollapsed) && (
        <div
          className={classNames(
            styles.sessionButtonsContainer,
            standalone && styles.standalone,
            isFullScreen && styles.fullScreen
          )}
        >
          <ActionButton
            action={t("containers.rightPanel.preview.restart")}
            icon={<IconReload />}
            onClick={handleRestartSessionClick}
            size="lg"
            target="_blank"
            type="outlined"
            containerClassName={styles.sessionButton}
            className={styles.button}
          />
          <ActionButton
            containerClassName={styles.sessionButton}
            action={
              sessionDuration.current === undefined
                ? t("containers.rightPanel.preview.endSession")
                : t("containers.rightPanel.preview.timeRemaining", {
                    time: formatTime(timers[currentDeviceType] || 0),
                  })
            }
            hoverAction={t("containers.rightPanel.preview.endSession")}
            onClick={handleEndSessionClick}
            icon={<IconStop />}
            size="lg"
            target="_blank"
            type="primary"
            className={styles.button}
          />
          {standalone && <div className={styles.divider} />}
        </div>
      )}

      {!standalone && privateKey && !isRightPanelCollapsed && (
        <div
          className={classNames(
            styles.previewOptionsRow,
            styles.rowWidth,
            styles.debugMode,
            (isLicensed || !showReadyToPublish) && styles.isLicensed
          )}
        >
          <div className={styles.debugSwitch}>
            <Switch
              inputComponent
              checked={debugMode}
              label={t("common.debugMode")}
              inline
              onChange={() => {
                if (currentDevice.isLaunched) {
                  toast(
                    t("containers.rightPanel.preview.devToolsToggleWarning"),
                    { type: "warning" }
                  );
                  return;
                }

                setDebugMode((prevState) => !prevState);
              }}
            />
          </div>

          <div
            className={classNames(
              styles.previewTopButtons,
              !debugMode && styles.hidden
            )}
          >
            <Button
              className={styles.buttonExternal}
              disabled={!publicKey}
              href={debugUrl}
              target="_blank"
              title={t("common.appetize")}
              type="text"
            >
              <h2
                className={classNames(styles.previewHeaderTitle, {
                  [styles.licensed]: isLicensed,
                  [styles.readyToPublishHidden]:
                    disabled || !showReadyToPublish || isBackupPreview,
                })}
              >
                {t("common.appetize")}
              </h2>

              {publicKey && <IconExternal />}
            </Button>

            <Button
              className={styles.buttonExternal}
              disabled={!publicKey}
              onClick={handleDevToolsClick}
              title={t("common.devTools")}
              type="text"
            >
              <h2
                className={classNames(styles.previewHeaderTitle, {
                  [styles.licensed]: isLicensed,
                  [styles.readyToPublishHidden]:
                    disabled || !showReadyToPublish || isBackupPreview,
                })}
              >
                {t("common.devTools")}
              </h2>

              {publicKey && <IconExternal />}
            </Button>
          </div>
        </div>
      )}

      {!standalone &&
        privateKey &&
        !isRightPanelCollapsed &&
        showDownloadButton && (
          <ActionButton
            containerClassName={classNames(
              styles.downloadButton,
              styles.download
            )}
            className={classNames(styles.previewButtonItem, styles.download)}
            action={t("common.download")}
            icon={<IconDownload />}
            onClick={() => {
              if (isMobile) {
                dispatch(uiUpdated({ isRightPanelCollapsed: true }));
              }
              router.push(
                urls.appBuilder(privateKey, SECTION.build, "", "app-download"),
                undefined,
                { shallow: true }
              );
            }}
            size="lg"
            type="outlined"
          />
        )}
      {standalone && (
        <div className={styles.standaloneButtons}>
          {build.downloadLinks.androidLink && (
            <>
              <Tooltip
                label={t("sections.build.dailyLimitReached")}
                placement="top"
                disabled={!isDownloadsBlocked}
              >
                <ActionButton
                  action={t(
                    "containers.rightPanel.preview.downloadAndroidApkButton"
                  )}
                  disabled={
                    !appnumHashed ||
                    !build.downloadLinks.androidLink ||
                    isDownloadsBlocked
                  }
                  icon={<IconDownload />}
                  onClick={() => {
                    downloadBuild({
                      privateKey: appnumHashed,
                      type: "apk",
                      eventName: "android_apk",
                      leadKey: "android_apk_downloaded",
                      licensed: isLicensed,
                      email,
                    });
                  }}
                  size="lg"
                  target="_blank"
                  type="outlined"
                />
              </Tooltip>
              <ActionButton
                action={t(
                  "containers.rightPanel.preview.installAndroidApkButton"
                )}
                disabled={!appnumHashed || !build.downloadLinks.androidLink}
                className={styles.installAppButton}
                icon={<IconInstall />}
                onClick={() => {
                  setPrevUrl(window.location.href);
                  dispatch(uiUpdated({ modalShown: "installApp" }));
                  router.push(urls.share(publicKey, "apk"), undefined, {
                    shallow: true,
                  });
                }}
                size="lg"
                target="_blank"
                type="outlined"
              />
            </>
          )}
          <Tooltip
            label={t("sections.build.dailyLimitReached")}
            placement="top"
            disabled={!isDownloadsBlocked}
          >
            <ActionButton
              className={styles.cloneButton}
              action={t("containers.rightPanel.preview.cloneButton")}
              disabled={isDownloadsBlocked}
              href={urls.clone(publicKey)}
              icon={<IconClone height={18} width={18} />}
              size="lg"
              target="_blank"
              type="primary"
            />
          </Tooltip>
        </div>
      )}
      {!standalone &&
        !isRightPanelCollapsed &&
        showReadyToPublish &&
        !isLicensed &&
        !isBackupPreview &&
        !isNewApp &&
        !cloneKey && (
          <ReadyToPublish onClose={() => setShowReadyToPublish(false)} />
        )}

      {isInstallAppModalOpen && (
        <InstallAppModal
          onClose={() => {
            if (prevUrl) {
              router.push(prevUrl, undefined, {
                shallow: true,
              });
            } else {
              router.push(
                urls.share(
                  publicKey,
                  DeviceType.androidPhone.toLocaleLowerCase()
                ),
                undefined,
                {
                  shallow: true,
                }
              );
            }
            dispatch(uiUpdated({ modalShown: "none" }));
          }}
          onRequestClose={() => {
            if (prevUrl) {
              router.push(prevUrl, undefined, {
                shallow: true,
              });
            } else {
              router.push(
                urls.share(
                  publicKey,
                  DeviceType.androidPhone.toLocaleLowerCase()
                ),
                undefined,
                {
                  shallow: true,
                }
              );
            }

            dispatch(uiUpdated({ modalShown: "none" }));
          }}
          isOpen
          publicKey={publicKey}
        />
      )}
    </div>
  );
};

export default Preview;
