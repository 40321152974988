import React, { SVGProps } from "react";

const IconReload: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 13.5C4.5 14.9834 4.93986 16.4334 5.76397 17.6668C6.58808 18.9001 7.75942 19.8614 9.12987 20.4291C10.5003 20.9968 12.0083 21.1453 13.4632 20.8559C14.918 20.5665 16.2544 19.8522 17.3033 18.8033C18.3522 17.7544 19.0665 16.418 19.3559 14.9632C19.6453 13.5083 19.4967 12.0003 18.9291 10.6299C18.3614 9.25943 17.4001 8.08809 16.1668 7.26398C14.9334 6.43987 13.4834 6 12 6H7.363L10.052 8.689L9 9.75L4.5 5.25L9 0.75L10.052 1.81L7.362 4.5H12C13.78 4.5 15.5201 5.02784 17.0001 6.01677C18.4802 7.00571 19.6337 8.41131 20.3149 10.0558C20.9961 11.7004 21.1743 13.51 20.8271 15.2558C20.4798 17.0016 19.6226 18.6053 18.364 19.864C17.1053 21.1226 15.5016 21.9798 13.7558 22.3271C12.01 22.6743 10.2004 22.4961 8.55585 21.8149C6.91131 21.1337 5.5057 19.9802 4.51677 18.5001C3.52784 17.0201 3 15.28 3 13.5H4.5Z"
      fill="black"
    />
  </svg>
);

export default IconReload;
