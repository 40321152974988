import React, { SVGProps } from "react";

const IconHome: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.459 1.66047C12.3259 1.55671 12.162 1.50037 11.9932 1.50037C11.8245 1.50037 11.6606 1.55671 11.5275 1.66047L0.75 10.0642L1.68225 11.2432L3 10.2157V19.5C3.00079 19.8976 3.15908 20.2786 3.44022 20.5598C3.72135 20.8409 4.10242 20.9992 4.5 21H19.5C19.8976 20.9992 20.2787 20.8409 20.5598 20.5598C20.8409 20.2786 20.9992 19.8976 21 19.5V10.2225L22.3177 11.25L23.25 10.071L12.459 1.66047ZM13.5 19.5H10.5V13.5H13.5V19.5ZM15 19.5V13.5C14.9996 13.1023 14.8414 12.721 14.5602 12.4397C14.279 12.1585 13.8977 12.0004 13.5 12H10.5C10.1023 12.0004 9.721 12.1585 9.43978 12.4397C9.15856 12.721 9.0004 13.1023 9 13.5V19.5H4.5V9.04647L12 3.20397L19.5 9.05397V19.5H15Z"
      fill="currentColor"
    />
  </svg>
);

export default IconHome;
