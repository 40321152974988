import crypto from "crypto";

import { appetizeSecretKey } from "./constants";

function createHmac(data: string) {
  const hmac = crypto.createHmac("sha256", appetizeSecretKey || "");
  hmac.update(data);
  return hmac.digest("hex");
}

export function generateSignedQuery(queryParams: string) {
  const hmac = createHmac(queryParams);
  const signedQuery = `${queryParams}&hmac=${hmac}`;
  return encodeURIComponent(signedQuery);
}
