import React, { SVGProps } from "react";

const IconStop: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 4.5V19.5H4.5V4.5H19.5ZM4.5 3H3V4.5V19.5V21H4.5H19.5H21V19.5V4.5V3H19.5H4.5Z"
      fill="white"
    />
  </svg>
);

export default IconStop;
