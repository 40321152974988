import React, { SVGProps } from "react";

const IconClose: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="20" fill="currentColor" />
    <path
      d="M26 15.05L24.95 14L20 18.95L15.05 14L14 15.05L18.95 20L14 24.95L15.05 26L20 21.05L24.95 26L26 24.95L21.05 20L26 15.05Z"
      fill="#0e0d08"
    />
  </svg>
);

export default IconClose;
