import classNames from "classnames";
import React from "react";

import IconInfo from "assets/images/icons/helpInfo.svg";
import Button from "components/common/Button";
import { useAppDispatch } from "stores";
import { uiShowDocsKeyUpdated, uiUpdated } from "stores/features/ui";
import { useHelpInfo } from "utils/hooks";

import styles from "./styles.module.scss";

interface Props {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  configKey?: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Tab: React.FC<Props> = ({
  active,
  children,
  className,
  configKey,
  href,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const [, helpInfoLink] = useHelpInfo(configKey);

  return (
    <div
      className={classNames(styles.tabContainer, className, {
        [styles.active]: active,
      })}
    >
      <Button
        className={classNames(styles.tab, {
          [styles.hasInfoLink]: helpInfoLink,
        })}
        href={href}
        onClick={onClick}
        shallow={href ? true : undefined}
        allowOnPreviewMode
      >
        {children}
      </Button>

      {helpInfoLink && (
        <Button
          className={styles.infoLink}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(uiUpdated({ showDocs: helpInfoLink }));
            dispatch(uiShowDocsKeyUpdated());
          }}
        >
          <IconInfo />
        </Button>
      )}
    </div>
  );
};

export default Tab;
