import classNames from "classnames";
import React, { SVGProps } from "react";

const IconIos: React.FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    className={classNames("icon-ios", className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5917 16.2493C14.9 17.2827 14.1667 18.291 13.05 18.3077C11.9333 18.3327 11.575 17.6493 10.3083 17.6493C9.03333 17.6493 8.64166 18.291 7.58333 18.3327C6.49166 18.3743 5.66666 17.2327 4.96666 16.2243C3.54166 14.166 2.45 10.3743 3.91666 7.82435C4.64166 6.55768 5.94166 5.75768 7.35 5.73268C8.41666 5.71602 9.43333 6.45768 10.0917 6.45768C10.7417 6.45768 11.975 5.56602 13.2667 5.69935C13.8083 5.72435 15.325 5.91602 16.3 7.34935C16.225 7.39935 14.4917 8.41602 14.5083 10.5243C14.5333 13.041 16.7167 13.8827 16.7417 13.891C16.7167 13.9493 16.3917 15.091 15.5917 16.2493ZM10.8333 2.91602C11.4417 2.22435 12.45 1.69935 13.2833 1.66602C13.3917 2.64102 13 3.62435 12.4167 4.32435C11.8417 5.03268 10.8917 5.58268 9.95833 5.50768C9.83333 4.54935 10.3 3.54935 10.8333 2.91602Z"
      fill="currentColor"
    />
  </svg>
);

export default IconIos;
