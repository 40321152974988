import classNames from "classnames";
import React, { useCallback, useRef } from "react";
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  Props as ReactSelectProps,
  SelectInstance,
  SingleValueProps,
} from "react-select";

import { useAppSelector } from "stores";
import { selectIsBackupPreview } from "stores/features/backups";

import styles from "./styles.module.scss";

interface Value {
  label: string;
  value: string;
  icon?: React.ReactNode;
}

const DropdownIndicator: React.ComponentType<
  DropdownIndicatorProps<Value, false, GroupBase<Value>>
> = (props) => (
  <components.DropdownIndicator {...props}>
    <svg
      className={styles.indicatorIcon}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 10L12 15L17 10H7Z" fill="currentColor" fillOpacity="0.54" />
    </svg>
  </components.DropdownIndicator>
);

const Option = ({
  children,
  data,
  ...otherProps
}: OptionProps<Value, false, GroupBase<Value>>) => (
  <components.Option data={data} {...otherProps}>
    {data.icon && <div className={styles.iconContainer}>{data.icon}</div>}
    {children}
  </components.Option>
);

const SingleValue = ({
  children,
  data,
  ...otherProps
}: SingleValueProps<Value, false, GroupBase<Value>>) => (
  <components.SingleValue data={data} {...otherProps}>
    {data.icon && <div className={styles.iconContainer}>{data.icon}</div>}
    {children}
  </components.SingleValue>
);

type Props = Omit<
  ReactSelectProps<Value, false, GroupBase<Value>>,
  "isMulti"
> & {
  beforeIcon?: React.ReactNode;
  options?: Value[];
  selectClassName?: string;
  type?: "primary" | "secondary" | "light";
  value?: Value;
};

const Select: React.FC<Props> = ({
  beforeIcon,
  className,
  isDisabled,
  options,
  selectClassName,
  type = "primary",
  value,
  ...otherProps
}) => {
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const refSelect = useRef<SelectInstance<Value, false>>(null);

  const handleClickIconContainer = useCallback(() => {
    if (isDisabled || isBackupPreview) return;

    if (refSelect.current) {
      refSelect.current.focus();
      refSelect.current.onMenuOpen();
    }
  }, [isDisabled, isBackupPreview]);

  return (
    <div
      className={classNames(
        styles.selectContainer,
        {
          [styles.disabled]: isDisabled || isBackupPreview,
          [styles.primary]: type === "primary",
          [styles.secondary]: type === "secondary",
          [styles.light]: type === "light",
          [styles.withIcon]: !!beforeIcon,
        },
        className
      )}
    >
      {beforeIcon && (
        <div
          className={classNames(
            styles.selectIconContainer,
            (isDisabled || isBackupPreview) && styles.disabled
          )}
          onClick={handleClickIconContainer}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
        >
          {beforeIcon}
        </div>
      )}

      <ReactSelect<Value, false, GroupBase<Value>>
        className={classNames(styles.select, selectClassName)}
        classNamePrefix="gnSelect"
        components={{ DropdownIndicator, Option, SingleValue }}
        isDisabled={isDisabled || isBackupPreview}
        options={options}
        ref={refSelect}
        value={value}
        {...otherProps}
      />
    </div>
  );
};

export default Select;
