import classNames from "classnames";
import React, { SVGProps } from "react";

const IconAndroid: React.FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    className={classNames("icon-android", className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8416 12.6248C13.4583 12.6248 13.1416 12.3165 13.1416 11.9331C13.1416 11.5498 13.4583 11.2498 13.8416 11.2498C14.225 11.2498 14.5416 11.5498 14.5416 11.9331C14.5416 12.3165 14.225 12.6248 13.8416 12.6248ZM6.17496 12.6248C5.79163 12.6248 5.47496 12.3165 5.47496 11.9331C5.47496 11.5498 5.79163 11.2498 6.17496 11.2498C6.55829 11.2498 6.86663 11.5498 6.86663 11.9331C6.86663 12.3165 6.55829 12.6248 6.17496 12.6248ZM14.0916 8.44981L15.4833 6.04981C15.5583 5.90815 15.5083 5.73315 15.375 5.65815C15.2333 5.57481 15.0583 5.62481 15 5.76648L13.575 8.19148C12.4583 7.68315 11.25 7.41648 9.99996 7.42481C8.72496 7.42481 7.49996 7.69981 6.44163 8.18315L5.03329 5.75815C4.95829 5.61648 4.78329 5.56648 4.64163 5.64981C4.49996 5.72481 4.45829 5.89981 4.53329 6.04148L5.91663 8.44148C3.54163 9.74148 1.90829 12.1498 1.66663 14.9998H18.3333C18.1 12.1581 16.475 9.74981 14.0916 8.44981Z"
      fill="currentColor"
    />
  </svg>
);

export default IconAndroid;
